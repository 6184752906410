import React from "react";
import Layout from "../components/structure/layout";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";

const BlogWrapper = styled.div`
  max-width: 400px;
  display: inline-flex;
  margin: 40px 0;
`;

const BlogImage = styled.img`
  object-fit: cover;
  margin-right: 10px;
`;

const BlogTitle = styled.h4`
  margin-right: 60px;
`;

const Blog = () => (
  <StaticQuery
    query={graphql`
      {
        allWordpressPost {
          edges {
            node {
              title
              link
              id
              featured_image_src {
                link
              }
            }
          }
        }
      }
    `}
    render={(props) => (
      <Layout>
        {props.allWordpressPost.edges.map((blogItem) => (
          <BlogWrapper key={blogItem.node.id}>
            <BlogImage
              src={blogItem.node.featured_image_src.link}
              width="89"
              height="89"
            />
            <BlogTitle
              dangerouslySetInnerHTML={{ __html: blogItem.node.title }}
            />
          </BlogWrapper>
        ))}
      </Layout>
    )}
  />
);

export default Blog;
